// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import clientService from "./../../../services/services"
import { setDeleteMessage } from "./../../../redux/navbar"

export const getIngredientData = createAsyncThunk(
  "appIngredient/getAllData",
  async (data) => {
    const response = await clientService.get("/admin/ingredient/list", data)
    return {
      params: data,
      data: response.data.data.data,
      totalPages: response.data.data.total,
    }
  }
)

export const deleteIngredient = createAsyncThunk(
  "appIngredient/deleteUser",
  async (id, { dispatch, getState }) => {
    const res = await clientService.remove(`/admin/ingredient/${id}`)
    if (res?.data?.status) {
      dispatch(
        setDeleteMessage({
          deleteMessageType: "success",
          deleteMessage: res?.data?.message,
        })
      )
    } else {
      dispatch(
        setDeleteMessage({
          deleteMessageType: "error",
          deleteMessage: res?.data?.message,
        })
      )
    }
    await dispatch(getIngredientData(getState().ingredients.params))
    return id
  }
)


export const appIngredientsSlice = createSlice({
  name: 'ingredients',
  initialState: {
    data: [],
    events: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getIngredientData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appIngredientsSlice.reducer
