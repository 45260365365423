/* eslint-disable */
import axios from "axios"
import config from "../config"
import { setLoader } from "../redux/loaderSlice"
import { store } from "../redux/store"

var token = localStorage.getItem("accessToken")

const axiosInterceptors = axios.create({
  baseURL: config.apiUrl,
  timeout: 35000,
  // token: localStorage.getItem('accessToken'),
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    // 'Content-Type': 'application/json',
    Authorization: "Bearer " + token,
    "Content-Type": "multipart/form-data",
  },
})

const clientService = {
  get: async (endPoint, data) => {
    // store.dispatch(setLoader(true))
    try {
      const res = await axiosInterceptors.get(endPoint, data)
      store.dispatch(setLoader(false))
      return res
    } catch (e) {
      store.dispatch(setLoader(false))
      return e
    }
  },
  getWithLoader: async (endPoint, data) => {
    store.dispatch(setLoader(true))
    try {
      const res = await axiosInterceptors.get(endPoint, data)
      store.dispatch(setLoader(false))
      return res
    } catch (e) {
      store.dispatch(setLoader(false))
      return e
    }
  },
  post: async (endPoint, data) => {
    try {
      store.dispatch(setLoader(true))
      const res = await axiosInterceptors.post(endPoint, data)
      store.dispatch(setLoader(false))
      return res
    } catch (e) {
      store.dispatch(setLoader(false))
      return e
    }
  },
  postWithNoLoader: async (endPoint, data) => {
    try {
      const res = await axiosInterceptors.post(endPoint, data)
      return res
    } catch (e) {
      return e
    }
  },
  remove: async (endPoint) => {
    try {
      store.dispatch(setLoader(true))
      const res = await axiosInterceptors.delete(endPoint)
      store.dispatch(setLoader(false))
      return res
    } catch (e) {
      store.dispatch(setLoader(false))
      return e
    }
  },
}

export default clientService
