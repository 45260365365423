// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import clientService from "./../../../services/services"
import { setDeleteMessage } from "./../../../redux/navbar"

export const getContentData = createAsyncThunk(
  "appContent/getAllData",
  async (data) => {
    const response = await clientService.get("/admin/content/list", data)
    return {
      params: data,
      data: response.data.data,
      totalPages: response.data.data.total,
    }
  }
)

export const appContentSlice = createSlice({
  name: 'contents',
  initialState: {
    data: [],
    events: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getContentData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appContentSlice.reducer
