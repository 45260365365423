// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
// import Moncierge from '../views/Moncierge/store'
import users from '../views/User/store'
import sessions from '../views/Sessions/store'
import categories from "../views/Categories/store"
import products from "../views/Products/store"
import movies from "../views/Movies/store"
import ingredients from "../views/Ingredients/store"
import contents from "../views/Content/store"
import quiz from "../views/Quiz/store"
import orders from "../views/Orders/store"
import sliders from "../views/SliderImage/store"
import sported from "../views/SportedLastWeek/store"
import loader from "./loaderSlice"
// import support from '../views/Support/store'
// import accounts from '../views/Accounts/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  // Moncierge,
  users,
  categories,
  sessions,
  products,
  movies,
  ingredients,
  contents,
  quiz,
  orders,
  sliders,
  sported,
  loader
  // support,
  // accounts
}

export default rootReducer
