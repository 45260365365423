import mock from '../mock'
const data = {
  profileData: {
    header: {
      avatar: require('@src/assets/images/portrait/small/avatar-s-2.jpg').default,
      username: 'Kitty Allanson',
      designation: 'UI/UX Designer'
    },
    userAbout: {
      about: 'Tart I love sugar plum I love oat cake. Sweet ⭐️ roll caramels I love jujubes. Topping cake wafer.',
      joined: 'November 15, 2015',
      lives: 'New York, USA',
      email: 'bucketful@fiendhead.org',
      website: 'www.pixinvent.com'
    },
    suggestedPages: [
      {
        avatar: require('@src/assets/images/avatars/12-small.png').default,
        username: 'Peter Reed',
        subtitle: 'Company',
        favorite: false
      },
      {
        avatar: require('@src/assets/images/avatars/1-small.png').default,
        username: 'Harriett Adkins',
        subtitle: 'Company',
        favorite: false
      },
      {
        avatar: require('@src/assets/images/avatars/10-small.png').default,
        username: 'Juan Weaver',
        subtitle: 'Company',
        favorite: false
      },
      {
        avatar: require('@src/assets/images/avatars/3-small.png').default,
        username: 'Claudia Chandler',
        subtitle: 'Company',
        favorite: false
      },
      {
        avatar: require('@src/assets/images/avatars/5-small.png').default,
        username: 'Earl Briggs',
        subtitle: 'Company',
        favorite: true
      },
      {
        avatar: require('@src/assets/images/avatars/6-small.png').default,
        username: 'Jonathan Lyons',
        subtitle: 'Beauty Store',
        favorite: false
      }
    ],
    twitterFeeds: [
      {
        imgUrl: require('@src/assets/images/avatars/5-small.png').default,
        title: 'Gertrude Stevens',
        id: 'tiana59 ',
        tags: '#design #fasion',
        desc: 'I love cookie chupa chups sweet tart apple pie ⭐️ chocolate bar.',
        favorite: false
      },
      {
        imgUrl: require('@src/assets/images/avatars/12-small.png').default,
        title: 'Lura Jones',
        id: 'tiana59 ',
        tags: '#vuejs #code #coffeez',
        desc: 'Halvah I love powder jelly I love cheesecake cotton candy. 😍',
        favorite: true
      },
      {
        imgUrl: require('@src/assets/images/avatars/1-small.png').default,
        title: 'Norman Gross',
        id: 'tiana59 ',
        tags: '#sketch #uiux #figma',
        desc: 'Candy jelly beans powder brownie biscuit. Jelly marzipan oat cake cake.',
        favorite: false
      }
    ],
    post: [],
    latestPhotos: [],
    suggestions: [
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
        name: 'Peter Reed',
        mutualFriend: '6 Mutual Friends'
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
        name: 'Harriett Adkins',
        mutualFriend: '3 Mutual Friends'
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
        name: 'Juan Weaver',
        mutualFriend: '1 Mutual Friends'
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
        name: 'Claudia Chandler',
        mutualFriend: '16 Mutual Friends'
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
        name: 'Earl Briggs',
        mutualFriend: '4 Mutual Friends'
      },
      {
        avatar: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default,
        name: 'Jonathan Lyons',
        mutualFriend: '25 Mutual Friends'
      }
    ],
    polls: [
      {
        name: 'RDJ',
        result: '82%',
        votedUser: [
          {
            img: require('@src/assets/images/portrait/small/avatar-s-12.jpg').default,
            username: 'Tonia Seabold'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-5.jpg').default,
            username: 'Carissa Dolle'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
            username: 'Kelle Herrick'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default,
            username: 'Len Bregantini'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
            username: 'John Doe'
          }
        ]
      },
      {
        name: 'Chris Hemsworth',
        result: '67%',
        votedUser: [
          {
            img: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
            username: 'Diana Prince'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
            username: 'Lois Lane'
          },
          {
            img: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
            username: 'Bruce Wayne'
          }
        ]
      }
    ]
  }
}

mock.onGet('/profile/data').reply(() => [200, data.profileData])
