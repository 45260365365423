// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import clientService from "./../../../services/services"
import { setDeleteMessage } from "./../../../redux/navbar"

export const getSlidersData = createAsyncThunk(
  "appSported/getAllData",
  async (data) => {
    const response = await clientService.get("/admin/sported", data)
    return {
      params: data,
      data: response.data.data,
      totalPages: response.data.data.total,
    }
  }
)

export const deleteSliders = createAsyncThunk(
  "appSported/deleteUser",
  async (id, { dispatch, getState }) => {
    const res = await clientService.remove(`/admin/sported/${id}`)
    if (res?.data?.status) {
      dispatch(
        setDeleteMessage({
          deleteMessageType: "success",
          deleteMessage: res?.data?.message,
        })
      )
    } else {
      dispatch(
        setDeleteMessage({
          deleteMessageType: "error",
          deleteMessage: res?.data?.message,
        })
      )
    }
    await dispatch(getSlidersData(getState().sliders.params))
    return id
  }
)

export const appSlidersSlice = createSlice({
  name: "sported",
  initialState: {
    data: [],
    events: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSlidersData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  },
})

export default appSlidersSlice.reducer
