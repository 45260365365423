// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import clientService from "./../../../services/services"
import { setDeleteMessage } from "./../../../redux/navbar"

export const getCategoryData = createAsyncThunk(
  "appCategory/getAllData",
  async (data) => {
    const response = await clientService.get("/admin/category/list", data)
    return {
      params: data,
      data: response.data.data,
      totalPages: response.data.data.total,
    }
  }
)

export const deleteCategory = createAsyncThunk(
  "appCategory/deleteUser",
  async (id, { dispatch, getState }) => {
    const res = await clientService.remove(`/admin/category/${id}`)
    if (res?.data?.status) {
      dispatch(
        setDeleteMessage({
          deleteMessageType: "success",
          deleteMessage: res?.data?.message,
        })
      )
    } else {
      dispatch(
        setDeleteMessage({
          deleteMessageType: "error",
          deleteMessage: res?.data?.message,
        })
      )
    }
    await dispatch(getCategoryData(getState().categories.params))
    return id
  }
)

export const appCategorySlice = createSlice({
  name: "categories",
  initialState: {
    data: [],
    events: [],
    total: 1,
    params: {},
    selectedUser: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  },
})

export default appCategorySlice.reducer
