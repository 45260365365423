import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loader: false,
}

export const appSlice = createSlice({
  name: "loader",
  initialState,

  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload
    },
  },
})

export const { setProfile, setLoader } = appSlice.actions
export const appState = (state) => state.app
export default appSlice.reducer
